<template>
	<section class="promotion-card" :class="{ 'expire': content.isExpired }">
        <div class="expired-note" v-if="content.isExpired">หมดช่วงโปรโมชั่นแล้ว</div>

        <div class="title">
            <h5>{{ content.title }}</h5>
            <p>ช่วงเวลา: {{ content.period }}</p>
            <span>สินค้าร่วมรายการ: <strong>{{ content.productIntegration }}</strong></span>
            <switch-button
                v-model="isActive">
                Active
            </switch-button>

            <router-link :to="helper.replaceRouteParam(routerPath.SELLER_PRODUCTS_PROMOTION_DETAIL, ':id', '1')">
                <b-icon icon="pencil"></b-icon>
            </router-link>
        </div>

        <div class="sub-promotion" v-for="(item, index) in content.subPromotions" :key="index">
            <div class="item" :style="{ borderLeftColor: item.color }">
                {{ item.title }}
            </div>
        </div>

        <div class="term">
            {{ content.term }}
        </div>
	</section>
</template>

<script>
import Helper from '@/services/helper';
import RouterPath from '@/router/path';

export default {
    data() {
        return {
            isActive: false,
            routerPath: RouterPath,
			helper: Helper,
        }
    },
	props: {
		content: Object
	},
    mounted() {
        this.isActive = this.content.isActive;
    },
	methods: {
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.promotion-card {
    max-width: 800px;
    border: 1px solid #ddd;
    padding: 20px;
    @include borderRadius(10px);
    @include boxShadow(0 0 10px rgba(100, 100, 100, 0.1));

    .expired-note {
        background: #cc0000;
        font-size: 13px;
        color: #fff;
        padding: 4px 10px;
        margin-bottom: 20px;
        text-align: center;
    }

    .title {
        position: relative;
        margin-bottom: 20px;

        h5 {
            font-weight: bold;
            font-family: 'Open Sans', 'Kanit';
            font-size: 16px;
            margin: 0;
            margin-bottom: 5px;
        }

        p {
            font-size: 13px;
            margin: 0;
        }

        span {
            font-size: 13px;
            display: block;
            margin-bottom: 5px;

            strong {
                color: #cc0000;
            }
        }

        a {
            position: absolute;
            top: 0;
            right: 0;
            width: 40px;
            height: 40px;
            background: #eee;
            font-size: 18px;
            color: #000;
            display: flex;
            align-items: center;
            justify-content: center;
            @include borderRadius(50%);

            &:hover {
                @include transform(rotate(360deg));
            }
        }
    }

    .sub-promotion {
        .item {
            margin-top: 5px;
            background: #fff;
            border: 1px solid #ddd;
            border-left: 4px solid #ccc;
            padding: 8px 15px;
            font-size: 13px;
        }
    }

    .term {
        font-size: 12px;
        margin-top: 20px;
    }
}
</style>