<template>
	<section>
		<h2>จัดการโปรโมชั่น</h2>

		<div class="text-center mb-3">
			<router-link :to="helper.replaceRouteParam(routerPath.SELLER_PRODUCTS_PROMOTION_DETAIL, ':id', 'new')" class="btn btn-main btn-add-new">สร้างโปรโมชั่น</router-link>
		</div>

		<template v-if="isResultReady">
			<div class="promotion" v-for="(item, index) in resultList" :key="index">
				<div class="priority">
					<span>{{ (index + 1) }}</span>
					<div class="re-arrange">
						<button><b-icon icon="chevron-up"></b-icon></button>
						<button><b-icon icon="chevron-down"></b-icon></button>
					</div>
				</div>
				<div class="item">
					<seller-group-promotion-card :content="item"></seller-group-promotion-card>

					<button class="btn-remove">
						<b-icon icon="x"></b-icon>
					</button>
				</div>
			</div>
		</template>

		<div class="no-data" v-if="isResultReady && resultList.length === 0">
			<b-icon icon="box-seam"></b-icon>
			<span>ไม่มีรายการโปรโมชั่น</span>
		</div>
	</section>
</template>

<script>
import SellerService from '@/services/sellerService';
import Helper from '@/services/helper';
import RouterPath from '@/router/path';
import SellerGroupPromotionCard from '@/components/seller/stocks/GroupPromotionCard';

export default {
	components: {
		SellerGroupPromotionCard
	},
	data() {
		return {
			routerPath: RouterPath,
			helper: Helper,
			isResultReady: false,
			resultList: []
		}
	},
	mounted() {
		this.getStockPromotion();
	},
	methods: {
		async getStockPromotion() {
			let loader = this.$modalLoader.render();
	
            const result = await SellerService.getStockPromotionV2();

			if (result.status == 401) {
				this.$router.push(RouterPath.SELLER_LOGIN);
			}

			this.isResultReady = true;
			this.resultList = result.data.resultList;

            loader.hide();
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

h2 {
	font-size: 24px;
	font-family: 'Open Sans', 'Kanit';
	font-weight: bold;
	margin-bottom: 55px;
	padding-bottom: 12px;
	color: #333;
	border-bottom: 1px solid #ccc;
}

.btn-add-new {
	padding: 10px 20px;
	font-size: 18px;
}

.promotion {
	display: flex;
	padding-left: 50px;
	padding-bottom: 30px;
	margin-top: 30px;
	border-bottom: 1px dashed #ddd;
	
	.priority {
		margin-right: 60px;

		span {
			display: flex;
			margin: auto;
			width: 40px;
			height: 40px;
			background: #ffc619;
			align-items: center;
			justify-content: center;
			font-size: 18px;
			font-weight: bold;
			@include borderRadius(50%);
		}

		.re-arrange {
			margin-top: 30px;

			button {
				display: block;
				border: 1px solid #ccc;
				background: #fff;
				width: 100px;
				height: 30px;
				margin-top: 5px;
			}
		}
	}

	.item {
		display: flex;
		align-items: flex-start;
		flex: 1;

		.btn-remove {
			background: none;
			border: none;
			font-size: 28px;
			color: #cc0000;
			margin-left: 30px;
		}
	}
}

.no-data {
	margin-top: 30px;
	border: 1px dashed #ddd;
	@include borderRadius(20px);
}
</style>